import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';
import HeatMaps from './bpmn/heatmaps';
import AddUser from './usermanagement/tenants/addUser';

const Login = lazy(() => import('./session/Login'));
const FrgtPswd = lazy(() => import('./session/forgotPassword'));
const Signup = lazy(() => import('./session/signup'));
const BuiltInAgents = lazy(() => import('./aistudio/builtInAgents'));
const ConfigureAgent = lazy(() => import('./aistudio/configureAgent'));
const CustomizeRequest = lazy(() => import('./aistudio/customizeRequest'));
const BPMN = lazy(() => import('./bpmn/bpmn'));
const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const DMN = lazy(() => import('./dmn/dmn'));
const APPStudio = lazy(() => import('./appstudio/appstudio'));
const Monitor = lazy(() => import('./bpmn/bpmnmonitor'));
const Javascript = lazy(() => import('./javascript/customscript'));
const Category = lazy(() => import('./category/category'));
const Form = lazy(() => import('./form/form'));
const ServiceHub = lazy(() => import('./servicehub/servicehub'));
const UserProfile = lazy(() => import('./usermanagement/userprofile'));
const ChangePaswd = lazy(() => import('./usermanagement/changePassword'));
const Users =  lazy(() => import('./usermanagement/usermanagement'));
const notification = lazy(() =>import('./notification/notification'))

class AppRoutes extends Component {
  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          <Route exact path="/home/dashboard" component={ Dashboard } />
          <Route exact path="/home/aistudio/ba" component={ BuiltInAgents } />
          <Route exact path="/home/aistudio/cn" component={ ConfigureAgent } />
          <Route exact path="/home/aistudio/cr" component={ CustomizeRequest } />
          <Route exact path="/home/bpmn/a" component={ BPMN } />
          <Route exact path="/home/bpmn/m" component={ BPMN } />
          <Route exact path="/home/bpmn/f" component={ BPMN } />
          <Route exact path="/home/bpmn/b" component={ BPMN } />
          <Route exact path="/home/bpmn/i" component={ BPMN } />
          <Route exact path="/home/bpmn/t" component={ BPMN } />
          <Route exact path="/home/appstudio" component={ APPStudio } />
          <Route exact path="/home/dmn" component={ DMN } />
          <Route exact path="/home/script" component={ Javascript } />
          <Route exact path="/home/monitor" component={ Monitor } />
          <Route exact path="/home/category" component={ Category } />
          <Route exact path="/home/form" component={ Form } />
          <Route exact path="/home/servicehub" component={ ServiceHub } />
          <Route exact path="/home/userprofile" component={ UserProfile } />
          <Route exact path="/home/users" component={ Users } />
          <Route exact path="/home/changePassword" component={ ChangePaswd } />
          <Route exact path="/home/notification" component={ notification } />
          <Route exact path="/home/createuser" component={ AddUser } />
          <Route exact path="/home/heatmaps" component={ HeatMaps } />
          <Route path="/login" component={ Login } />
          <Route path="/register" component={ Signup } />
          <Route path="/forgotpassword" component={ FrgtPswd } />
          <Route path="/" exact={true} >
            <Redirect to="/login" />
          </Route>
          <Route path="/home" exact>
            <Redirect to="/home/dashboard" />
          </Route>
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
import Axios from "axios";
var host = 'http://localhost:8001/api/';
var deployURL = 'http://localhost:40002/deploy/DigiFinance_Loan_Processing_BPMN/';
Axios.defaults.withCredentials = true;
var defaultheader = {
    withCredentials: false
};
if(window.location.href.indexOf("localhost") == -1){
    host = "/api/";
}
export var showLoadingIndicator = false;

getData('version').then(d => { console.log(d) });
getData('getdeployURL').then(d => {
    deployURL = d;
    console.log(deployURL);
});

export function deploy(id) {
    showLoadingIndicator = true;
    return new Promise((resolve, reject) => {
        Axios.post(deployURL + id, {}, defaultheader).then(response => {
            showLoadingIndicator = false;
            resolve(response.data);
        }, (err) => {
            showLoadingIndicator = false;
            console.log(err);
            reject(err);
        });
    });
}

export function postdata(url, data) {
    showLoadingIndicator = true;
    return new Promise((resolve, reject) => {
        Axios.post(host + url, data, defaultheader).then(response => {
            showLoadingIndicator = false;
            resolve(response.data);
        }, (err) => {
            showLoadingIndicator = false;
            console.log(err);
            reject(err);
        });
    });
}

export function fileUpload(url, formData) {
    showLoadingIndicator = true;
    return new Promise((resolve, reject) => {
        Axios.post(host + url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(response => {
            showLoadingIndicator = false;
            resolve(response.data);
        }, (err) => {
            showLoadingIndicator = false;
            console.log(err);
            reject(err);
        });
    });
}

export function deleteData(url) {
    showLoadingIndicator = true;
    return new Promise((resolve, reject) => {
        Axios.delete(host + url, defaultheader).then(response => {
            showLoadingIndicator = false;
            resolve(response.data);
        }, (err) => {
            showLoadingIndicator = false;
            console.log(err);
            reject(err);
        });
    });
}


export function getData(url) {
    showLoadingIndicator = true;
    return new Promise((resolve, reject) => {
        Axios.get(host + url, defaultheader).then(response => {
            showLoadingIndicator = false;
            resolve(response.data);
        }, (err) => {
            showLoadingIndicator = false;
            console.log(err);
            reject(err);
        });
    });
}


export function putData(url, data) {
    showLoadingIndicator = true;
    return new Promise((resolve, reject) => {
        Axios.put(host + url, data, defaultheader).then(response => {
            showLoadingIndicator = false;
            resolve(response.data);
        }, (err) => {
            showLoadingIndicator = false;
            console.log(err);
            reject(err);
        });

    });
}

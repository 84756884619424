import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getUserdetail,deleteUserDetail } from '../service/utility';
import { getData } from '../service/httputlity';
import  noimage  from '../../assets/images/faces-clipart/pic-1.png';

class Navbar extends Component {

  state = {
    fname:'',
    profilePic:noimage
  }
 

  componentDidMount() {
    var userdetail = getUserdetail();
    console.log(userdetail);
    if(userdetail){
      this.setState({fname:userdetail.fname});
      if(userdetail.profilepic){
        this.setState({profilePic:userdetail.profilepic});
      }
    }
  }


  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }

  logOut(){
    getData('logout').then((d)=>{
      document.getElementById("sidebar").remove();
      document.getElementById("navbar-id").remove();
      deleteUserDetail();
      window.location.href = '/';
    });
  }

  
  render () {
    return (
      <nav className="navbar p-0 fixed-top d-flex flex-row" id="navbar-id">
        <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo-mini" to="/"><img src={require('../../assets/images/logo-mini.svg')} alt="logo" /></Link>
        </div>
        <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
         
          <button className="navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
            <span className="mdi mdi-menu"></span>
          </button>
        
          <ul className="navbar-nav navbar-nav-right">
            <Dropdown alignRight as="li" className="nav-item">
              <Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret">
                <div className="navbar-profile">
                  <img className="img-xs rounded-circle" src={this.state.profilePic} alt="Profile Pic" />
                  <p className="mb-0 d-none d-sm-block navbar-profile-name">{this.state.fname}</p>
                  <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="navbar-dropdown bg-white preview-list navbar-profile-dropdown-menu">
                <Dropdown.Item  onClick={()=>{window.location.href =("/#/home/userprofile");}} className="preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-secondary rounded-circle">
                      <i className="mdi mdi-settings text-primary"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1">Settings</p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item  onClick={()=>{window.location.href =("/#/home/changepassword");}} className="preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-secondary rounded-circle">
                      <i className="bi bi-key-fill text-info"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1">Change Password</p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item   className="preview-item" onClick={()=>{this.logOut()}}>
                  <div className="preview-thumbnail">
                      <div className="preview-icon bg-secondary rounded-circle">
                        <i className="mdi mdi-logout text-danger"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject mb-1">Log Out</p>
                    </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
            <span className="mdi mdi-format-line-spacing"></span>
          </button>
        </div>
      </nav>
    );
  }
}

export default Navbar;

import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import Logo from "../../../src/assets/images/vizplatform_logo.png";
import LogoSM from "../images/logo-sm.png";
import { Collapse } from 'react-bootstrap';
import { getUserdetail } from '../service/utility';

class Sidebar extends Component {

  state = {

  };


  toggleMenuState(menuState, event) {
    // Prevent collapse if the event target is a submenu link
    if (event && event.target.closest('.sub-menu')) {
      return; // Do not toggle the menu when clicking on a submenu item
    }
  
    // Close all menus except the one clicked
    this.setState(prevState => {
      const newState = {};
      Object.keys(prevState).forEach(key => {
        newState[key] = false;  // Close all other menus
      });
      newState[menuState] = !prevState[menuState]; // Toggle clicked menu
      return newState;
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/home/scripts', state: 'appsMenuOpen' },
      { path: '/home/bpmn', state: 'basicUiMenuOpen' },
      { path: '/home/aistudio', state: 'aistudioMenuOpen' },
      { path: '/home/dashboard', state: 'tablesMenuOpen' },
      { path: '/home/users', state: 'iconsMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));
  }

  render() {
    var json = getUserdetail();
    var showUser = false;
    var showSubUser = false;
    if (json) {
      if (json.accountType === "Admin") {
        showUser = true;
      }
      else if (json.accountType === "Tenant" || json.accountType === "Tenant-User") {
        showSubUser = true;
      }
    }
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div id='sidebar-brand' className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <a className="sidebar-brand brand-logo" href="index.html">
            {/* <i class="fa fa-cube"></i> <span class="sidebar-nav-mini-hide">VIZUAL PLATFORM</span> */}
            {/* <img src={require('../../assets/images/logo.svg')} alt="logo" /> */}
            <img class="responsive" src={Logo} alt="" /> <h6>Vizual Platform</h6>
          </a>
          <a className="sidebar-brand brand-logo-mini" href="index.html">
            {/* <i class="fa fa-cube"></i> <p class="sidebar-nav-mini-hide">VIZUAL</p> */}
            {/* <img src={require('../../assets/images/logo-mini.svg')} alt="logo" /> */}
            <img class="responsive" src={LogoSM} alt="" />
          </a>
        </div>
        <ul className="nav">
          <li className={this.isPathActive('/home/dashboard') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/home/dashboard">
              <span className="menu-icon"><i className="mdi mdi-home"></i></span>
              <span className="menu-title"><Trans>Dashboard / Report</Trans></span>
            </Link>
          </li>
          <li className={`${this.isPathActive('/home/users') ? 'nav-item menu-items active' : 'nav-item menu-items'} ${showUser ? '' : 'hide'} `}>
            <Link className="nav-link" to="/home/users">
              <span className="menu-icon"><i className="mdi mdi-account"></i></span>
              <span className="menu-title"><Trans>Users</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/home/appstudio') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/home/appstudio">
              <span className="menu-icon"><i className="mdi mdi-camera-front-variant"></i></span>
              <span className="menu-title"><Trans>App Studio</Trans></span>
            </Link>
          </li>
          <li className={'nav-item menu-items submenu' + (this.isPathActive('/home/aistudio/ba') ? ' active' : '')} >
            <Link to="/home/aistudio/ba" className={(this.state.aistudioMenuOpen ? 'nav-link menu-expanded ' : 'nav-link') }
             onClick={(e) => this.toggleMenuState('aistudioMenuOpen', e)}  >
              <span className="menu-icon"><i className="mdi mdi-speedometer"></i></span>
              <span className="menu-title"><Trans>AI Studio</Trans></span>
            </Link>
            <Collapse in={this.state.aistudioMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className={'nav-item menu-items ' + (this.isPathActive('/home/aistudio/ba') ? ' active' : '')}> <Link className={this.isPathActive('/home/aistudio/ba') ? 'nav-link active' : 'nav-link'} to="/home/aistudio/ba"><Trans>Built-in Agents</Trans></Link></li>
                  <li className={'nav-item menu-items ' + (this.isPathActive('/home/aistudio/cn') ? ' active' : '')}> <Link className={this.isPathActive('/home/aistudio/cn') ? 'nav-link active' : 'nav-link'} to="/home/aistudio/cn"><Trans>Configure a Agent</Trans></Link></li>
                  <li className={'nav-item menu-items ' + (this.isPathActive('/home/aistudio/cr') ? ' active' : '')}> <Link className={this.isPathActive('/home/aistudio/cr') ? 'nav-link active' : 'nav-link'} to="/home/aistudio/cr"><Trans>Customization request</Trans></Link></li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li className={'nav-item menu-items submenu' + (this.isPathActive('/home/bpmn/a') ? ' active' : '')}>
            <Link to="/home/bpmn/a" className={(this.state.basicUiMenuOpen ? 'nav-link menu-expanded ' : 'nav-link') } 
            onClick={(e) => this.toggleMenuState('basicUiMenuOpen', e)}  >
              <span className="menu-icon"><i className="mdi mdi-speedometer"></i></span>
              <span className="menu-title"><Trans>BPMN 2.0</Trans></span>
            </Link>
            <Collapse in={this.state.basicUiMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className={'nav-item menu-items ' + (this.isPathActive('/home/bpmn/f') ? ' active' : '')}> <Link className={this.isPathActive('/home/bpmn/f?type=Finance') ? 'nav-link active' : 'nav-link'} to="/home/bpmn/f?type=Finance"><Trans>Finance</Trans></Link></li>
                  <li className={'nav-item menu-items ' + (this.isPathActive('/home/bpmn/i') ? ' active' : '')}> <Link className={this.isPathActive('/home/bpmn/i?type=Insurance') ? 'nav-link active' : 'nav-link'} to="/home/bpmn/i?type=Insurance"><Trans>Insurance</Trans></Link></li>
                  <li className={'nav-item menu-items ' + (this.isPathActive('/home/bpmn/m') ? ' active' : '')}> <Link className={this.isPathActive('/home/bpmn/m?type=Manufacturing') ? 'nav-link active' : 'nav-link'} to="/home/bpmn/m?type=Manufacturing"><Trans>Manufacturing</Trans></Link></li>
                  <li className={'nav-item menu-items ' + (this.isPathActive('/home/bpmn/b') ? ' active' : '')}> <Link className={this.isPathActive('/home/bpmn/b?type=Banking') ? 'nav-link active' : 'nav-link'} to="/home/bpmn/b?type=Banking"><Trans>Banking</Trans></Link></li>
                  <li className={'nav-item menu-items ' + (this.isPathActive('/home/bpmn/t') ? ' active' : '')}> <Link className={this.isPathActive('/home/bpmn/t?type=Telecom') ? 'nav-link active' : 'nav-link'} to="/home/bpmn/t?type=Telecom"><Trans>Telecom</Trans></Link></li>
                </ul>
              </div>
            </Collapse>
          </li>


          {/* <li className={this.isPathActive('/home/bpmn') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/home/bpmn">
              <span className="menu-icon"><i className="mdi mdi-speedometer"></i></span>
              <span className="menu-title"><Trans>BPMN 2.0</Trans></span>
            </Link>
          </li> */}
          <li className={this.isPathActive('/dmn') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/home/dmn">
              <span className="menu-icon"><i className="mdi mdi-cellphone-settings-variant"></i></span>
              <span className="menu-title"><Trans>DMN Rules</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/script') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/home/script">
              <span className="menu-icon"><i className="mdi mdi-script-text-outline"></i></span>
              <span className="menu-title"><Trans>JavaScript</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/notification') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/home/notification">
              <span className="menu-icon"><i className="mdi mdi-bell"></i></span>
              <span className="menu-title"><Trans>Notification</Trans></span>
            </Link>
          </li>

          {/* <li className={this.isPathActive('/monitor') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/home/monitor">
              <span className="menu-icon"><i className="mdi mdi-monitor"></i></span>
              <span className="menu-title"><Trans>BPMN Monitor</Trans></span>
            </Link>
          </li> */}


          <li className={this.isPathActive('/category') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/home/category">
              <span className="menu-icon"><i className="mdi mdi-graph"></i></span>
              <span className="menu-title"><Trans>Category</Trans></span>
            </Link>
          </li>


          <li className={this.isPathActive('/form') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/home/form">
              <span className="menu-icon"><i className="mdi mdi-format-page-break"></i></span>
              <span className="menu-title"><Trans>Forms</Trans></span>
            </Link>
          </li>

          <li className={this.isPathActive('/home/servicehub') ? 'nav-item menu-items' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/home/servicehub">
              <span className="menu-icon"><i className="mdi mdi-settings-outline"></i></span>
              <span className="menu-title"><Trans>Service Hub</Trans></span>
            </Link>
          </li>

          <li className={` ${this.isPathActive('/createuser') ? 'nav-item menu-items' : 'nav-item menu-items'} ${showSubUser ? '' : 'hide'} `}>
            <Link className="nav-link" to="/home/createuser">
              <span className="menu-icon"><i className="mdi mdi-person"></i></span>
              <span className="menu-title"><Trans>Users</Trans></span>
            </Link>
          </li>


        </ul>
        <p class="help">
          <span className="menu-icon"><i className="mdi mdi-help-circle-outline"></i></span>&nbsp; Help</p>
      </nav>

    );
  }

  isPathActive(path) {
    return (window.location.href.search(path) !== -1);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);